import Carousel from 'react-bootstrap/Carousel';
import './testimonials.css';
import { Col, Row } from 'react-bootstrap';

const testimonials_arr = [
    {
      username: 'JohnDoe',
      companyName: 'ABC Inc.',
      profileImage: 'user.jpeg',
      testimony: 'Working with ABC Inc. has been a fantastic experience. The team is highly skilled and dedicated. I would recommend their services to anyone!'
    },
    {
      username: 'JaneSmith',
      companyName: 'XYZ Corporation',
      profileImage: 'user.jpeg',
      testimony: 'I am impressed with the professionalism and expertise of XYZ Corporation. They delivered exceptional results and exceeded our expectations. A pleasure to work with!'
    },
    {
      username: 'SamJones',
      companyName: '123 Industries',
      profileImage: 'user.jpeg',
      testimony: '123 Industries has a top-notch team that goes above and beyond to deliver quality solutions. Their commitment to excellence is truly commendable.'
    },
    {
      username: 'EmilyClark',
      companyName: 'Tech Solutions Ltd.',
      profileImage: 'user.jpeg',
      testimony: 'Tech Solutions Ltd. provided us with innovative and cost-eff'
    }
];
  

function DarkVariantExample() {
  return (
    <Carousel id="testimonials" data-bs-theme="dark">
      <Carousel.Item>
        <Row>
            <Col xs={3}>

            </Col>
            <Col xs={9}>
            </Col>
        </Row>
        <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default DarkVariantExample;