import React from 'react';
import './index.css';
import blogs from '../../content/blogs.js'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis';


const BlogCard = (props) => {
  return (
    <Card className="text-left blogcard">
      {/* <Card.Header>
        <Row>
          <Col xs={6} style={{"textAlign": "left"}}>{props.blog.title}</Col>
        </Row>
        
      </Card.Header> */}
      <Card.Body>
        <Card.Text>
          <Row>
            <Col className='blogImg'>
              <img src={props.blog.img_url} />
            </Col>
            </Row>
            <Row>
            <Col>
            {/* <Card.Title style={{"textAlign":"left", "padding": "1em"}}>{props.blog.title}</Card.Title> */}
            <div style={{"display": "flex","padding":"1em"}}><img src="drishte.jpeg" alt="Avatar" className="avatar" /><Col style={{"textAlign": "left", "color": "#212529","padding":"1.5em 0 0 1em", "width":"100%"}}>Author: Drishte Madan</Col></div>
            {/* <p className='bodyText'>
              
            {props.blog.body}
            </p> */}
            <LinesEllipsis
            text={props.blog.el_text}
            maxLine='7'
            ellipsis='...'
            trimRight
            basedOn='letters'
            style={{"padding": "1em", "marginBottom": "3em", "textAlign":"justify"}}
            />
            <span style={{"padding": "1em"}} className='readMore'><a href={`#/blogs/${props.blog.blog_id}`}>Read More</a></span>
            </Col>
          </Row>
        </Card.Text>
        
      </Card.Body>
      <Card.Footer  className="text-muted">
        <span style={{"padding": "1em"}} className='postDate'>2 days ago</span>
        
      </Card.Footer>
      
    </Card>
  );
}

function Blog() {
  console.log(blogs)
  return (
    <div id='blogs' className="container">
       <div className="header-section">
            <h2 className="title">Blogs</h2>
            {blogs.map((blog) => <BlogCard blog={blog} />)}
        </div>
    </div>
  );
}

export default Blog;
