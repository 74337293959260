import React from 'react';
import { useParams } from 'react-router-dom';
import Blogs from '../../content/blogs';
import "./index.css";
function SingleBlog(props) {
    const {id} = useParams();
    const blog = Blogs.filter((bl) => bl.blog_id == id )
    console.log('Blogs:', blog);
  return (
    <div style={{"position":"relative","zIndex":"10"}} className="content">
      <div style={{"padding": "1em 0 0 3em", "cursor": "pointer"}}><a href='#/blogs' style={{"color":"black"}}><i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}Back</a></div>
        <div className='image-hero-blog'>
            <div className='blog-img-s left-side'><img src={blog[0].img_url} /></div>
            <div className='blog-body-s' style={{"textAlign": "justify", "color":"black","marginBottom":"2em"}} dangerouslySetInnerHTML={ { __html: blog[0].body } }></div>
        </div>
    </div>
  );
}

export default SingleBlog;
