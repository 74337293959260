import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';


// import { useState } from 'react';
import { NavLink} from 'react-router-dom';

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 47)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 67)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="52"
        height="4"
        rx="2"
        transform="translate(294 57)"
        fill="#574c4c"
      />
    </g>
  </svg>
);

function OffCanvasExample({ name, desc,img_url, book_url, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const bookNow = (book_url) => {
    window.open(book_url, "_blank")
  }

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow} className="me-2">
        {name}
      </Button> */}
      <h6 onClick={handleShow}>{name}</h6>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{"textAlign": "center"}}> 
        <img src={img_url} style={{"borderRadius": "2em", "width": "15em", "margin": "0 auto"}} />
          <p style={{"textAlign": "justify","padding": "1em"}}>{desc}</p>
          <button onClick={() => bookNow(book_url) } className='btn btn-primary' style={{"color":"#FFF", "backgroundColor":"#215e7b", "border": "none", 'borderRadius':'1em'}}>Book now</button>
          {/* <Link to={book_url} target='blank' style={{"zIndex":"90"}}><img style={{"width":"15em"}} src="booknow.png" /></Link> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="containerNav">
        <a href='#' className="logo">
          <img src='/tmhc_logo.png' />
        </a>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements navlist  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink onClick={() => setShowNavbar(false)} to="/">Home</NavLink>
            </li>
            {/* 
            <li>
              <NavLink to="/about">About Us</NavLink>
            </li>*/}
            <li>
              <NavLink onClick={() => setShowNavbar(false)} to="/blogs">Blogs</NavLink>
            </li> 
            <li>
            <NavLink id="servicesNav">
              <NavDropdown title="Services" id="basic-nav-dropdown-services">
                <NavDropdown.Item onClick={() => setShowNavbar(false)}><OffCanvasExample book_url="https://calendly.com/tmhcnow/50?month=2024-01" placement='end' name='Individual Therapy' desc="Psychotherapy is a transformative journey to understand and manage thoughts and emotions, leading to reduced distress, improved relationships, heightened self-awareness, stress management skills, and solutions to specific problems. Speak to one of the top licensed RCI registered clinical psychologists." img_url="s1.png" /></NavDropdown.Item>
                {/* <NavDropdown.Item><OffCanvasExample placement='end' name='Couple/Family Therapy' desc="" img_url="" /></NavDropdown.Item> */}
                <NavDropdown.Item onClick={() => setShowNavbar(false)}><OffCanvasExample book_url="https://calendly.com/tmhcnow/50?month=2024-01" placement='end' name='Psychological Assessment' desc="Our team of clinical psychologists employ a comprehensive series of assessments to precisely pinpoint areas of concern and tailor individualized treatment plans for you. You can seek assessments for Anxiety, Depression, Borderline Personality Disorder, Panic Attack Disorder, Obsessive-Compulsive Disorder, ADHD,  Intellectual Disability, Learning Disability, Autism, personality IQ and neuropsychogical assessments" img_url="s2.png" /></NavDropdown.Item>
                <NavDropdown.Item onClick={() => setShowNavbar(false)}><OffCanvasExample book_url="https://api.whatsapp.com/send?phone=9810607429" placement='end' name='Workshops and Seminars' desc="Explore our workshops covering a range of topics such as stress management, mindfulness,employee assistance program (EAP), youth mental health, anger management, conflict resolution, work-life balance, and much more." img_url="s3.png" /></NavDropdown.Item>
              </NavDropdown>
            </NavLink>
            </li>
            <li> 
            <NavLink className="contact-us">
                <NavDropdown title="Contact Us" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={() => setShowNavbar(false)} href="tel: +919810607429">Call: +9198106 07429</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setShowNavbar(false)} target="_blank" href="mailto: tmhc.therapy@gmail.com">
                    Email: tmhc.therapy@gmail.com
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setShowNavbar(false)} href="https://api.whatsapp.com/send?phone=9810607429"> 
                    Chat with us
                  </NavDropdown.Item>
                </NavDropdown>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

