// Services.js
import React from 'react';
import './index.css';

function Services() {
    const services = [
        {
            image: 'individual.png',
            title: 'Individual Therapy',
            description: 'Our Individual Therapy service provides a confidential and supportive environment where clients can explore and address personal challenges. Our experienced therapists build a one-on-one therapeutic relationship tailored to the unique needs of each individual, fostering self-discovery, coping skills, and personal growth.'
        },
        {
            image: 'couple.png',
            title: 'Couple/ Family Therapy',
            description: 'In our Couple/Family Therapy service, we extend our support to relationships, offering a dedicated space for couples or families to navigate challenges and strengthen connections. Our therapists facilitate improved communication, understanding, and collaboration, guiding families and couples toward healthier dynamics and more fulfilling relationships.'
        },
        {
            image: 'assessment.png',
            title: 'Psychological Assessment',
            description: 'Our Psychological Assessment and Diagnosis service employs evidence-based methods to comprehensively evaluate mental health conditions. Through a thorough assessment process, we aim to provide a clear understanding of individual needs, enabling us to formulate personalized treatment plans tailored to each client\'s unique circumstances.'
        },
        {
            image: 'individual.png',
            title: 'Workshops and Seminars',
            description: 'Engaging Workshops and Seminars are an integral part of our services, designed to empower individuals and groups. These informative and interactive sessions enhance mental health awareness and equip participants with practical tools for personal growth and well-being. By addressing various aspects of mental health, our workshops aim to promote resilience, self-care, and a proactive approach to overall mental well-being.'
        }
    ]
  return (
    <div id='services' className="container mt-5">
        <div className="header-section">
            <h2 className="title">Our Services</h2>
        </div>

      <div className="row">
        {services.map((service) => {
            return (
                <div className="col-md-3 mb-3">
                    <div className="card">
                        <h5 className="card-title">{service.title}</h5>
                        <div className='card-img'>
                            <img
                            src={service.image}
                            className="card-img-top"
                            alt="Service 1"
                            />
                        </div>
                        <div className="card-body">
                        {/* <p className="card-text">
                            {service.description}
                        </p> */}
                        </div>
                    </div>
                </div>
            )
        })}
        
      </div>
    </div>
  );
}

export default Services;
