import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Route, Routes } from 'react-router-dom';

//components
import Hero from './components/hero/hero';
import About from './components/about/about';
import Services from './components/services';
import Blogs from './components/blogs';
import SingleBlog from './components/blogs/singleBlog';
import {Navbar} from './components/navigation';
import { HashRouter } from 'react-router-dom';
import { WhatsappIcon } from './components/hero/hero';


function App() {

  return (
    <>
     <HashRouter>
       <Navbar />
      <Routes>
        <Route path="/" Component={Hero} /> 
        <Route path='/services' Component={Services} />
        <Route path='/about' Component={About} />
        <Route path='/blogs' Component={Blogs} />
        <Route path='/blogs/:id' Component={SingleBlog} />
      </Routes>
      <WhatsappIcon />
    </HashRouter>
    </>
  );
}

export default App;