// AboutUs.js
import React from 'react';

function AboutUs() {
  return (
    <div className="container mt-5">
      <h3 className="mb-4">About Us</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="text-center mb-4">
            <img
              src="Drishte.jpeg"
              alt="About Us"
              className="img-fluid rounded-circle"
            />
            <h4>Dr. Drishte Madan</h4>
            <p>CEO & Founder</p>
          </div>
        </div>

        <div className="col-md-6">
          <h3>Our Mission</h3>
          <p>
            At Therapy Sessions, our mission is to provide high-quality therapy
            services to help individuals improve their mental and emotional
            well-being. We believe in the power of therapy to transform lives
            and promote personal growth.
          </p>

          <h3>Our Team</h3>
          <p>
            Our team of experienced and compassionate therapists is dedicated
            to supporting you on your journey to better mental health. We offer
            a variety of therapy approaches tailored to meet your unique needs.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
