import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Stack from 'react-bootstrap/Stack';
import './hero.css';
import DarkVariantExample from '../testimonials/testimonials';
export const WhatsappIcon = () => {
  return (
    <div className="floating_btn">
      <a target="_blank" href="https://api.whatsapp.com/send?phone=9810607429">
        <div className="contact_icon">
          <i className="fa fa-whatsapp my-float"></i>
        </div>
      </a>
      <p className="text_icon">Chat with us</p>
    </div>
  )
}

const Hero = () => {

  return (
    <div className='containerDiv'>
      <Container className='mainContent containerNav'>
        <Stack gap={6}>  
          <Row>
          <Col className="hero-left-side" md={4} xs={12} >
          <h2 className='top-heading1'>Psychological</h2>
          <h1 className='helpword'>HELP</h1>
          <h5 className='heroText' style={{color: '#56b0bb'}}>
          Navigating through life's challenges can sometimes feel overwhelming, and seeking support is a courageous step toward a happier, healthier you!
            </h5>
          <h5 style={{color: '#56b0bb'}}>Ready to take the first step?</h5>
          <button className='btn bookAppointmentHero'><a style={{borderRadius: '1em'}} className='bookappointmentHero' href='https://calendly.com/tmhcnow/50' target="_blank">Consult Now</a></button>
          </Col>
          <Col md={8} xs={12} className='hero-img'>
          <img
              src="/hero.png"
              alt="hero"
              className='heroImg'
            />
          </Col>
        </Row>
        </Stack> 
        <WhatsappIcon />
      </Container>
        {/* <section>
          <DarkVariantExample />
        </section> */}
      </div>
  );
};
export default Hero;
